import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="auth"
export default class extends Controller {
  connect() {
  }

  signIn() {
    $('#loading_app').addClass('d-flex')
    $('#invalid-sign-in-response').hide()
    grecaptcha.ready(() => {
      grecaptcha.execute($(this.element).data().recaptchaKey, {action: 'submit'}).then((token) => {
          // Add your logic to submit to your backend server here.
        let form_data = $(this.element).serializeArray()
        form_data.push({name: 'recaptchaToken', value: token})
        $.ajax({
          method: 'POST',
          dataType: 'JSON',
          url: '/login/sign_in',
          data: form_data
        }).done((data) => {
          if(data.code == 200) {
            window.location = '/'
          } else {
            $('#loading_app').removeClass('d-flex')
            $('#invalid-sign-in-response').show()
            $('#invalid-sign-in-response').text(data.message)
          }
        })
      });
    });
  }
}
