import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="back--ia-raw-materials"
export default class extends Controller {
  connect() {
    var rootStyle = getComputedStyle(document.body);
    this.datas = $(this.element).data().semester
    window.colors = {
      'primaryColor': rootStyle.getPropertyValue("--primary-color").trim(),
      'foregroundColor': $('body').css('backgroundColor'),
      'separatorColor': rootStyle.getPropertyValue("--separator-color").trim(),
      'themeColor': rootStyle.getPropertyValue("--theme-color-1").trim()
    }

    this.toporderIaCaches = {}

    this.displayRawMaterial()
  }

  clickMaterial(e) {
    if(!$(e.currentTarget).hasClass('active')) {
      $('#toporder-ia-raw-materials__left-column .active').removeClass('active')
      $(e.currentTarget).addClass('active')
      $('.toporder-ia-raw-materials__right-column__details.active').removeClass('active')
      $(`#toporder-ia-raw-materials__right-column__details--${$('#toporder-ia-raw-materials__left-column .active').data().material}`).addClass('active')
      Chart.getChart('toporder-ia-raw-materials__canvas').destroy()
      this.displayRawMaterial()
    }
  }

  displayRawMaterial() {
    let material = $('#toporder-ia-raw-materials__left-column .active').data().material
    $('#toporder-ia-raw-materials__right-column__title').text($('#toporder-ia-raw-materials__left-column .active').data().name)
    if(this.toporderIaCaches[material]) {
      $('#toporder-ia-raw-materials__toporder-ia-explanation').html(this.toporderIaCaches[material])
      this.generateCanvasDatas(material)
    } else {
      $('#loading_app').addClass('d-flex')
      $.ajax({
        method: 'POST',
        dataType: 'json',
        url: '/back/toporder_prompt_entity/question',
        data: {
          aiQuestionType: 'RAW_MATERIAL_PRICE_ESTIMATION',
          OverrideValue: {
            RawMaterialName: $('#toporder-ia-raw-materials__left-column .active').data().name,
            RawMaterialLastSixMonthsPrices: this.datas[material].values.slice(1).join(', ')
          }
          
        },
      }).done((data) => {
        $('#loading_app').removeClass('d-flex')
        this.toporderIaCaches[material] = data.answer.content
        $('#toporder-ia-raw-materials__toporder-ia-explanation').html(data.answer.content)
        this.generateCanvasDatas(material)
      })
    }

  }

  generateCanvasDatas(material) {
    new LineChart(
      $('#toporder-ia-raw-materials__canvas'),
      {
        labels: this.datas[material].labels.slice(1),
        sets: {
          "Prix": this.datas[material].values.slice(1)
        },
        type: 'line',
        legend: `$`,
        precision: 2
      },
      window.colors,
      window
    )
  }
}
