import { Controller } from "@hotwired/stimulus"
import { initSelect2 } from 'shared/select2_init'; 
// Connects to data-controller="franchisor--statistics--statistics-abstract-filter"
export default class extends Controller {
  get autoRefresh() {
    return false
  }

  connect() {
    this.element['controller'] = this
    this.loaded = false
    $.ajax({
      dataType: 'json',
      url: '/franchisor/statistics/v2/load_arbo'
    }).done((data) => {
      this.datas = data
      this.load_select()
      setTimeout(() => {
        this.startDate = $(this.element).data().startDate
        this.endDate = $(this.element).data().endDate
        $('#statistics-v2-filters__rangedatepicker-1').daterangepicker({
          parentEl: ".filter_card",
          maxDate: moment().add(60, 'days'),
          maxYear: 2030,
          minYear: 2000,
          changeYear: true,
          changeMonth: true,
          showDropdowns: true,
          ranges: {
            "Aujourd'hui": [moment(), moment()],
            'Hier': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
            'Les 7 derniers jours': [moment().subtract(6, 'days'), moment()],
            'Les 30 derniers jours': [moment().subtract(29, 'days'), moment()],
            'Le mois précédent': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
            "L'année en cours": [moment().startOf('year'), moment()],
            "L'année précédente": [moment().subtract(1, 'year').startOf('year'), moment().subtract(1, 'year').endOf('year')],
            'Les 30 prochains jours': [moment(), moment().add(30, 'days')]
          },
          startDate: moment($('.filter_card').data().datesOne.split('-')[0].split('/').reverse().join('-')),
          endDate: moment($('.filter_card').data().datesOne.split('-')[1].split('/').reverse().join('-'))
        }, (start, end, picker) => {
          if(picker == "Personnalisé") {
            $('#statistics-v2-filters__rangedatepicker-1 span').text(start.format('MMMM D, YYYY') + ' - ' + end.format('MMMM D, YYYY'))
          } else {
            $('#statistics-v2-filters__rangedatepicker-1 span').text(picker)
          }
          $('.filter_card').data().datesOne = `${start.format('DD/MM/YYYY')}-${end.format('DD/MM/YYYY')}`
          $('.filter_card').data().dates = `${start.format('DD/MM/YYYY')}-${end.format('DD/MM/YYYY')}`
          this.startDate = start.format('DD/MM/YYYY')
          this.endDate = end.format('DD/MM/YYYY')
        });  

        if(this.autoRefresh) {
          this.filter()
        } else {
          this.setUrls(this.prepareParams())
        }        
      }, 200)
    })    
  }

  load_select() {
    setTimeout(() => {
      let filter = $('.franchisor_stats_filter_group_type .active').data().filter
      let html = `
        <legend class='legend-v2'>${{ArboGroup: "Groupes", FranchiseOwner: 'Franchisé', ArboShop: 'Boutique'}[filter]}</legend>
        <select multiple class='optional statistics-v2-filters__multiple-select'>
          ${this.populate_options(filter)}
        </select>
      `
      $('#franchisor_stats_filter_groups_select').html(html)
      initSelect2($('#franchisor_stats_filter_groups_select select'))
      this.loaded = true
    }, 100)

  }

  populate_options(filter) {
    let html = ''
    $.each(this.datas[filter], (id, name) => {
      let selected = false
      if(!this.loaded && $(this.element).data().shops.split('_').includes(id.toString())) {
        selected = true
      }
      html += `<option value="${id}" ${selected ? 'selected' : ''}>${name}</option>`
    })
    return html
  }  

  prepareParams() {
    let params = {}
    if(this.startDate) {
      params.startDate = this.startDate.split('/').join('-')
      params.endDate = this.endDate.split('/').join('-')
    }

    if($('#statistics-v2-filters__input-ht').length) {
      params.ca = $('#statistics-v2-filters__input-ht').prop('checked') ? 'HT' : 'TTC'
      $('.ca_choice').text(params.ca)
      $('.filter_card').data().ca = params.ca
    }

    if($('#statistics-v2-filters__group-orders').length) {
      params.group_orders = $('#statistics-v2-filters__group-orders').val()
      $('.filter_card').data().grouporders = params.group_orders
    }

    if($('#statistics-v2-filters__product-filter').length && $('#statistics-v2-filters__product-filter').val() != 'all') {
      params.productUniqueId = $('#statistics-v2-filters__product-filter').val()
    }

    if($('#statistics-v2-filters__contact-type').length && $('#statistics-v2-filters__contact-type').val() != 'all') {
      params.contact_type = $('#statistics-v2-filters__contact-type').val()

    }
    if($('.filter_card').length) {
      $('.filter_card').data().contactType = params.contact_type
    }

    if($('#statistics-v2-filters__day-filter').length && $('#statistics-v2-filters__day-filter').val() != 'all') {
      params.day_filter = $('#statistics-v2-filters__day-filter').val()
    }

    params.shops = $('#franchisor_stats_filter_groups_select select').val().join('_')
    params.meta = $('.franchisor_stats_filter_group_type .active').data().filter
    $('.filter_card').data('shops', params.shops)
    $('.filter_card').data('meta', params.meta)
    
    
    return params    
  }

  setUrls(params) {
    let query = new URLSearchParams(params).toString()
    $.each($('.menu_statistics_v2_link'), (i, a) => {
      $(a).attr('href', `${$(a).attr('href').split('?')[0]}?${query}`)
    })  
  }

  filter() {
    let params = this.prepareParams()
    console.log(params)
    this.setUrls(params)
    this.refresh(params)
  }
}
