import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="catalog--product-ia-qr-code"
export default class extends Controller {
  copyShareUrl() {

    var dummy = document.createElement("textarea");
    //dummy.style.display = 'none'
    document.body.appendChild(dummy);
    dummy.textContent = $('#qr-code-ia-product-container__link').val();
    dummy.select();
    document.execCommand("copy");
    document.body.removeChild(dummy);

    flashMessage('', `Le lien de partage a été copié dans le presse papier`, 'success')
  }

  downloadQrCode() {
    var a = $("<a>")
        .attr("href", $('#qr-code-ia-product img').attr('src'))
        .attr("download", "qrcode.png")
        .appendTo("body");

    a[0].click();

    a.remove();
  }
  
}
