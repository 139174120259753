import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="catalog--product-ia-action"
export default class extends Controller {
  connect() {
    this.element['controller'] = this
  }

  modalHeader(type) {
    let html = `
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11.4258 4.45923C11.5637 3.84692 12.4363 3.84692 12.5742 4.45923L13.7732 9.78197C13.8231 10.0037 13.9963 10.1769 14.218 10.2268L19.5408 11.4258C20.1531 11.5637 20.1531 12.4363 19.5408 12.5742L14.218 13.7732C13.9963 13.8231 13.8231 13.9963 13.7732 14.218L12.5742 19.5408C12.4363 20.1531 11.5637 20.1531 11.4258 19.5408L10.2268 14.218C10.1769 13.9963 10.0037 13.8231 9.78197 13.7732L4.45923 12.5742C3.84692 12.4363 3.84692 11.5637 4.45923 11.4258L9.78197 10.2268C10.0037 10.1769 10.1769 10.0037 10.2268 9.78197L11.4258 4.45923Z" fill="#BB7DE8"/>
        <path d="M3.71903 0.424693C3.88463 -0.141564 4.68679 -0.141565 4.8524 0.424692L5.50709 2.66338C5.56362 2.85666 5.71476 3.00781 5.90805 3.06433L8.14674 3.71903C8.71299 3.88463 8.71299 4.6868 8.14674 4.8524L5.90805 5.50709C5.71476 5.56362 5.56362 5.71477 5.50709 5.90805L4.8524 8.14674C4.6868 8.71299 3.88463 8.71299 3.71903 8.14674L3.06433 5.90805C3.00781 5.71477 2.85666 5.56362 2.66338 5.50709L0.424693 4.8524C-0.141564 4.6868 -0.141564 3.88463 0.424693 3.71903L2.66338 3.06433C2.85666 3.00781 3.00781 2.85666 3.06433 2.66338L3.71903 0.424693Z" fill="#D6B1F1"/>
      </svg>
    `
    if(type && type == 'qrcode') {
      html += `
        <span class='font-semibold ml-2'>Fiche produit QR Code</span>
      `
    } else {
      html += `
        <span class='font-semibold ml-2'>Toporder IA</span>
        <span class='font-medium'> : ${$('#product_name').val()}</span>
      `
    }
    $('#modal_rails_5 .modal-title').html(html)
  }

  askQuestion(e) {
    this.prepareQuestion($(e.currentTarget).data().questionType) 
  }

  prepareQuestion(question_type) {
    initModal()
    if(question_type == 'AUTOMATIC_PRODUCT_DATA_NUTRI_SCORE') {
      $('#modal_rails_5 .modal-dialog').addClass('full-height new-modal')
    } else {
      $('#modal_rails_5 .modal-dialog').addClass('modal-xl full-height new-modal')      
    }
    $('#modal_rails_5 .modal-body').addClass('p-0')
    $('#modal_rails_5').modal('show')
    this.modalHeader()
    $('#modal_rails_5 .modal-body').html(`
      <div id="toporder-ia-modal-product" class='toporder-ia-modal-product--requesting-ia' data-controller="catalog--product-ia-modale" data-question-type="${question_type}">
        <svg id='toporder-ia-modal-product--loader' width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M64 32C64 38.7577 61.8606 45.342 57.8885 50.8091C53.9164 56.2763 48.3155 60.3456 41.8885 62.4338C35.4616 64.5221 28.5384 64.5221 22.1115 62.4338C15.6845 60.3456 10.0836 56.2762 6.11145 50.8091C2.13936 45.342 -5.9078e-07 38.7577 0 32C5.9078e-07 25.2423 2.13936 18.658 6.11146 13.1909C10.0836 7.72375 15.6845 3.65444 22.1115 1.56619C28.5385 -0.522064 35.4616 -0.522063 41.8885 1.56619L39.9108 7.65295C34.7692 5.98235 29.2308 5.98235 24.0892 7.65295C18.9476 9.32356 14.4668 12.579 11.2892 16.9527C8.11149 21.3264 6.4 26.5938 6.4 32C6.4 37.4062 8.11149 42.6736 11.2892 47.0473C14.4668 51.421 18.9476 54.6764 24.0892 56.347C29.2308 58.0177 34.7692 58.0177 39.9108 56.347C45.0524 54.6764 49.5332 51.421 52.7108 47.0473C55.8885 42.6736 57.6 37.4062 57.6 32H64Z" fill="#BB7DE8"/>
        </svg>
        <p id="toporder-ia-modal-product__main-waiting-message">Veuillez patientez quelques instants</p>
        <p id="toporder-ia-modal-product__sub-waiting-message">Toporder IA compile vos données</p>

      </div>
    `)

    $('#modal_rails_5 .modal-footer').html(`
      <div class='w-100 d-flex justify-content-between' data-controller="catalog--product-ia-footer">
        <button type='button' class='btn new-btn new-btn-border-grey' data-dismiss="modal" aria-label="Close">Annuler</button>
        <button type='button' disabled='disabled' class='btn new-btn new-btn-success' data-action='click->catalog--product-ia-footer#applyModifications'>Appliquer les modifications <i class="fas fa-check"></i></button>
      </div>
    `)
  }

  displayQrCode() {
    $('#modal_rails_5 .modal-body').removeClass('p-0')
    $('#modal_rails_5 .modal-dialog').removeClass('full-height')
    $('#modal_rails_5 .modal-body').html(`
      <div id='qr-code-ia-product-container' data-controller="catalog--product-ia-qr-code">
        <p id='qr-code-ia-product-container__product-title'>${$('#product_name').val()}</p>
        <p id='qr-code-ia-product-container__subtitle'>Facilitez l’accès à vos fiches produits en magasin grâce à un simple scan.</p>
        <div class='d-flex justify-content-center'>
          <div id='qr-code-ia-product'></div>
        </div>
        <p id='qr-code-ia-product-container__link-text'>Lien :</p>
        <div id='qr-code-ia-product-container__link-container'>
          <input id='qr-code-ia-product-container__link' value='${$('.edit_product_form').data().showcaseUrl}' readonly='readonly' disabled="disabled"/>
          <div data-action="click->catalog--product-ia-qr-code#copyShareUrl">
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path id="Icon" d="M4.16699 12.5003H3.33366C2.89163 12.5003 2.46771 12.3247 2.15515 12.0122C1.84259 11.6996 1.66699 11.2757 1.66699 10.8337V3.33366C1.66699 2.89163 1.84259 2.46771 2.15515 2.15515C2.46771 1.84259 2.89163 1.66699 3.33366 1.66699H10.8337C11.2757 1.66699 11.6996 1.84259 12.0122 2.15515C12.3247 2.46771 12.5003 2.89163 12.5003 3.33366V4.16699M9.16699 7.50033H16.667C17.5875 7.50033 18.3337 8.24652 18.3337 9.16699V16.667C18.3337 17.5875 17.5875 18.3337 16.667 18.3337H9.16699C8.24652 18.3337 7.50033 17.5875 7.50033 16.667V9.16699C7.50033 8.24652 8.24652 7.50033 9.16699 7.50033Z" stroke="#4A5463" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </div>
        </div>

        <div id='qr-code-ia-product-container__download-container'>
          <button type='button' data-action="catalog--product-ia-qr-code#downloadQrCode">
            <span>Télécharger l'image</span>
            <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path id="Icon" d="M18 12.5V15.8333C18 16.2754 17.8244 16.6993 17.5118 17.0118C17.1993 17.3244 16.7754 17.5 16.3333 17.5H4.66667C4.22464 17.5 3.80072 17.3244 3.48816 17.0118C3.17559 16.6993 3 16.2754 3 15.8333V12.5M6.33333 8.33333L10.5 12.5M10.5 12.5L14.6667 8.33333M10.5 12.5V2.5" stroke="#394453" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>

          </button>
        </div>

      </div>
    `)

    new QRCode(
      document.getElementById("qr-code-ia-product"),
      $('.edit_product_form').data().showcaseUrl
    );
  }

  showQrCode() {
    $('#modal_rails_5').modal('show')
    $('#modal_rails_5 .modal-dialog').addClass('new-modal')
    this.modalHeader('qrcode')
    this.displayQrCode()

    $('#modal_rails_5 .modal-footer').html(`
      <div class='w-100 d-flex justify-content-end' data-controller="catalog--product-ia-footer">
        <button type='button' class='btn new-btn new-btn-border-grey' data-action='catalog--product-ia-footer#regenerateQrCode'>Regénérer le QR Code</button>
      </div>
    `) 
  }
}
